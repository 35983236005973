.App {

    font-family: 'ProximaNovaSoft', sans-serif;

    .version {
        position: fixed;
        bottom: 2px;
        right: 2px;
        font-size: 12px;
        color: #CCCCCC;
    }
    
    .big-button {
        text-decoration: none;
        color: $cinza-1;
        padding: 20px;
        font-size: 2em;
        border: 1px solid $cinza-1;
        margin: 20px;
        display: block;

        &:hover {
            background-color: lighten($color: $rosa, $amount: 16);
            color: darken($color: $rosa, $amount: 16);
        }
    }
    .spinner-little {
        text-align: center;
    }

    .loading {
        width: 100%;
        height: 100%;
        background-color: rgba(lighten( $rosa, 17% ), 0.8);
        
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;



        .spinner {
            
            background-color: white;
            color: $cinza-1;
            text-align: center;
            padding: 40px;
            box-shadow:  2px 2px 4px 0px rgba(0,0,0,0.36);
           
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 4px;
            
            svg {
                color: $azul;
                font-size: 3em;
            }

            .text {
                margin-top: 20px;
                font-family:'ProximaNovaSemiBold', Courier, monospace;
                font-size: 1em;
            }

            p.small {
                font-size: 0.8em;
                line-height: 1.5em;
            }
          
        }

    }
    
    ::selection {
        color: white;
        background: $rosa;
      }

   
    
}