

header {
    height: auto;
    padding: 20px 10px 10px;
    border-bottom: 1px solid $azul;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.progenos-logo {
    width: 250px;
    display: block;
    

    img {
        width: 100%;

    }
}

.side-actions {
    font-size: 2em;
    margin-top: 20px;
    display: flex;

    .userName {
        font-size: 14px;
        text-align: right;
        padding-top: 12px;
        color: $cinza-1;
        padding-right: 10px;
        strong {
            font-size: 16px;
        }
    }

    .button-icon{
        color: $cinza-1;
        background-color: transparent;
        border: none;
        padding: 10px;
        margin: 0 2px;
        font-size: 1em;
        cursor: pointer;

        &:hover {
            color: $azul;
        }
    }
    
}