
.report-main {

    button.dia-d {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
    }

    button.hideTrick {
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        color: transparent;
    }

    div.subHeader {
        display: flex;
        justify-content: space-between;
        align-content: flex-end;
        align-items: flex-end;
    }

    div.filtro {
        display: inline-block;
        margin-right: 30px;
    }

    table.report-info {
        margin-top: 20px;
        margin-left: 20px;
        text-align: left;
        tr {
            th ,td {
                padding: 5px;
    
            }
    
            td {
                background-color: #CCC;
            }
        }
    
        
    }
    
    
        .grupo-exames {

            h5 {
                font-size: 16px;
                padding: 7px;
                background-color:  #CCC;
            }
    
            padding: 20px 20px 0;
    
            .sub-grupo {
                padding: 0 10px 20px;

                table {
                    width: 100%;

                    thead {
                        
                        tr th{
                          
                           

                            background-color: transparent;

                        }
                    }
                    tr {

                        td {
                            padding: 5px 2px;

                            &:first-of-type {
                                width: calc(50% - 150px);
                            }

                            &:nth-child(2){
                                width: 100px;
                                text-align: right;

                            }
                            &:nth-child(3) {
                                width: 70px;
                                text-align: left;
                            }

                            &:nth-child(4) {
                                text-align: center;
                                width: 25%;
                                word-wrap: break-word
                            }

                            &:nth-child(5) {
                                text-align: center;
                                width: 25%;
                                word-wrap: break-word
                            }

                            
                        }

                        &:nth-child(even) {
                            background-color: lighten($color: #CCCCCC, $amount: 10%);
                        }

                    }
                }
            }
        }


    .loading-results {
        display: flex;
        margin: 40px 20px;
        .spinner-little {
            margin-right: 10px;
        }
    }
    

}
