.login{
    width: 80vw;
    max-width: 300px;    

    padding: 40px;
    border: 1px solid $cinza-1;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
    .progenos-logo {
        width: 170px;
        margin: auto;
        padding: 20px 0;
    }

    form {

        
        
    }

    .new-pass-rules {
        color: $cinza-1;
        p {
            margin-top: 4px;
            margin-bottom: 7px;
        }

        ul {
            padding-left: 17px;
        }
        padding-bottom: 14px;
    }
}