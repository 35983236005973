div.references {
    table {
        width: 100%;
        padding: 20px;

        tr th {
            text-align: left;
            background-color: $header;
            color: white;
            padding: 5px;
            font-weight: bolder;
        }

        tr {
            &:hover {
                td {
                    background-color:rgb(233, 233, 233); ;
                }
                
            }
            td {
            background: rgb(242, 242, 242);
            padding: 5px;
            }


        }
    }
}