.busca-registro {
    h4 {
        margin-bottom: 5px;
    }

    form {
        display: flex;
        //justify-content: space-around;
        background-color: lighten($color: $cinza-1, $amount: 30);
        margin: 20px 0;
        .input {
            margin: 20px 0 20px 20px;
            
            display: inline-block;

        }

        button {
            margin: 20px 0 20px 20px;
        }
        width: 100%;
    }

    table {
        width: 100%;

        thead {
            background-color: $azul;
            color: white;
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: lighten($color: $cinza-1, $amount: 30);
                }

                &:hover {
                    background-color: lighten($color: $azul, $amount: 30);
                    color: white;

                    button {
                        color: white;

                    }
                }

                td {
                    padding: 0 7px;
                    button {
                        background-color: transparent;
                        color: $azul;
                        border: none;
                        padding: 10px;
                        cursor: pointer;
                        width: 100%;
                        font-family: 'ProximaNovaSemiBold', sans-serif;
                        font-size: 14px;
                    }

                }
            }
        }

        tr {
            height: 30px;
        }
    }
}
.step-frame {
    max-width: 400px;
    text-align: center;
    margin: 40px auto 20px;

    img {
        padding-left: 40px;
    }
}
.executar-aquisicao {
    button.classic {
        margin: 10px
    }
}

.status-ok{
   background-color: green;
        color: white;
        font-weight: bold;
    
}