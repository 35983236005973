form.registyForm {
      
    margin: 1%;
    width: 99%;

    .input-element {
        outline: none;
        border: 1px solid $cinza-1;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 100%;
        box-sizing: border-box;    
          
        
    }
    
    .Invalid {
        border: 1px solid red;
        background-color: #FDA49A;
    }
    
    .parologias {
        border: 1px solid $cinza-1;
        padding: 20px 20px 0;
        margin: 1%;
        width: 93.5%;

        label {
            margin-bottom: 10px;
        }
    }

}

.registro-form {
    margin: 20px auto ;

    h2 {
        margin-left: 20px;
    }
    
    div.input {
        width: 31%;
        margin: 10px 0;
        padding: 0 1%;
        display: inline-block;
        input, select, textarea {
            width: 100%;
            font-family: 'ProximaNovaSoft', sans-serif;
        }
        input[type=checkbox] {
            width: auto;
            display: inline;
        }

    }

    .register-success {
        margin: 0 20px;
        font-family: 'ProximaNovaSoft', sans-serif;
        color: $cinza-2;

        span.register-id {
            font-family: 'ProximaNovaSemiBold', sans-serif;
            color: $azul;
            font-size: 16px;
        }

        button {
            margin-right: 20px;
        }
    }
}

form {
    button {
        background-color: $azul;
        color: white;
        border: none;
        padding: 7px 17px;
        font-family: 'ProximaNovaSemiBold', sans-serif;
        font-size: 16px;
        border-bottom: 1px solid darken($color: $azul, $amount: 10);
        border-right: 1px solid darken($color: $azul, $amount: 10);
        border-radius: 3px;

        &:disabled {
            background-color: lighten($color: $azul, $amount: 20);
            border-bottom: 1px solid darken($color: $azul, $amount: 0);
            border-right: 1px solid darken($color: $azul, $amount: 0);
            cursor: not-allowed;
        }

        &:hover {
            
        
        }

        &:active {
            border: none;
            border-top: 1px solid darken($color: $azul, $amount: 10);
            border-left: 1px solid darken($color: $azul, $amount: 10);
        }
    }

    div.input  {
        position: relative;

        &.wide {
            width: 97.3%;
        }
    }

    .checkbox-container {

        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input[type=checkbox] {
            position: absolute;
            opacity: 0;            
            height: 0;
            width: 0;
            left: -1000px;
        }
    
        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: -5px;
            left: 0;
            height: 25px;
            width: 25px;            
            background-color: lighten($color: $rosa, $amount: 40);
            border: 1px solid $cinza-1;
            cursor: pointer;
        }

        label {
            font-size: 16px;
            font-family: 'ProximaNovaSoft', sans-serif;
            cursor: pointer;
        }
        
        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            background-color: lighten($color: $rosa, $amount: 15);
        }
        
        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
            background-color: $azul;
        }
        
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the checkmark/indicator */
       .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

    }


    input, select, textarea {
        color: $cinza-2;
        font-size: 16px;
        padding: 9px;
        border-radius: 3px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;
        
        &:focus {
            box-shadow: 0px 0px 5px 0px $rosa;
        }        
    }


    label {
        font-family:'ProximaNovaSemiBold', Courier, monospace;
        color: $cinza-2;
        font-size: 12px;
        display: block;
    }

    .field {
        margin: 20px 0;

        &.button{
            text-align: right;
        }
        
    }
    .wide-field {
        width: 98%;
        text-align: right;
        margin-top: 20px;
    }
    input {
        
        display: block;
        clear: both;
        width: 100%;
        margin-top: 2px;
        border: 1px solid $cinza-1;
        
    }

    
}

.message {
    font-family: 'ProximaNovaSemiBold', sans-serif;
    font-size: 16px;
    color: $cinza-2;
    &.error {
        color: $vermelho;

    }
}

button.classic {
    background-color: $azul;
    color: white;
    border: none;
    padding: 7px 17px;
    font-family: 'ProximaNovaSemiBold', sans-serif;
    font-size: 16px;
    border-bottom: 1px solid darken($color: $azul, $amount: 10);
    border-right: 1px solid darken($color: $azul, $amount: 10);
    border-radius: 3px;

    &:disabled {
        background-color: lighten($color: $azul, $amount: 20);
        border-bottom: 1px solid darken($color: $azul, $amount: 0);
        border-right: 1px solid darken($color: $azul, $amount: 0);
        cursor: not-allowed;
    }

    &:hover {
        
    
    }

    &:active {
        border: none;
        border-top: 1px solid darken($color: $azul, $amount: 10);
        border-left: 1px solid darken($color: $azul, $amount: 10);
    }
}