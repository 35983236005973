.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    width: 100%;
    height: 100%;
    z-index: 999;

    .modal {
        background-color: white;
        width: 90%;
        max-width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        padding: 20px;

        h4 {
            margin-top: 0;
        }

        .actions {
            display: flex;
            align-items: flex-end;
            flex-direction: row-reverse;

                button{
                margin-left: 10px;
                background-color: $azul;
                color: white;
                border: none;
                padding: 7px 17px;
                font-family: 'ProximaNovaSemiBold', sans-serif;
                font-size: 16px;
                border-bottom: 1px solid darken($color: $azul, $amount: 10);
                border-right: 1px solid darken($color: $azul, $amount: 10);
                border-radius: 3px;

                &:disabled {
                    background-color: lighten($color: $azul, $amount: 20);
                    border-bottom: 1px solid darken($color: $azul, $amount: 0);
                    border-right: 1px solid darken($color: $azul, $amount: 0);
                    cursor: not-allowed;
                }

                &:hover {
                    
                
                }

                &:active {
                    border: none;
                    border-top: 1px solid darken($color: $azul, $amount: 10);
                    border-left: 1px solid darken($color: $azul, $amount: 10);
                }
            }
        }
    }
}